var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Alignment"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAlignment) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("By default the pagination component is left aligned. Change the alignment to ")]), _c('code', [_vm._v("center, right")]), _c('span', [_vm._v(" (")]), _c('code', [_vm._v("right")]), _c('span', [_vm._v(" is an alias for ")]), _c('code', [_vm._v("end")]), _c('span', [_vm._v(" )")]), _c('span', [_vm._v(", or ")]), _c('code', [_vm._v("fill")]), _c('span', [_vm._v(" by setting the prop ")]), _c('code', [_vm._v("align")]), _c('span', [_vm._v(" to the appropriate value.")])]), _c('div', {
    staticClass: "demo-spacing-0"
  }, [_c('span', [_vm._v("Radios using options")]), _c('div', {
    staticClass: "my-1"
  }, [_c('b-form-radio', {
    attrs: {
      "name": "vuexy-radio",
      "value": "default",
      "inline": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Default ")]), _c('b-form-radio', {
    attrs: {
      "name": "vuexy-radio",
      "value": "right",
      "inline": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Right ")]), _c('b-form-radio', {
    attrs: {
      "name": "vuexy-radio",
      "value": "center",
      "inline": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Center ")]), _c('b-form-radio', {
    attrs: {
      "name": "vuexy-radio",
      "value": "fill",
      "inline": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Fill ")])], 1), _c('b-pagination', {
    attrs: {
      "total-rows": _vm.rows,
      "base-url": "#",
      "align": _vm.selected
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }