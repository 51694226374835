var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Separated"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSeparated) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To make space between seprated icons of pagination use ")]), _c('code', [_vm._v(".prev-item")]), _c('span', [_vm._v(" class for the ")]), _c('code', [_vm._v("prev-class")]), _c('span', [_vm._v(" prop and ")]), _c('code', [_vm._v(".next-item")]), _c('span', [_vm._v(" for the ")]), _c('code', [_vm._v("next-class")]), _c('span', [_vm._v(" prop.")])]), _c('div', {
    staticClass: "demo-spacing-0"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.rows,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }