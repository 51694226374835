var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-basic')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-separated')], 1), _c('b-col', {
    attrs: {
      "xl": "6"
    }
  }, [_c('pagination-color')], 1), _c('b-col', {
    attrs: {
      "xl": "6"
    }
  }, [_c('pagination-button-content')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-goto')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-size')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-pill')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-alignment')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }